.paginacion {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    color: $color-textos;

    .paginacion-moverse {
        background-color: $color-secundario;
        padding: 10px;
        text-decoration: none;
        color: $color-textos;
        cursor: pointer;

        &:hover {
            background-color: #837365;
        }
    }

    .lista-paginacion {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .pagina-actual {
            background-color: #837365;
        }

        li a {
            text-decoration: none;
            font-weight: bold;
            color: $color-textos;
            padding: 10px;

            &:hover {
                background-color: #837365;
            }
        }

        li {
            list-style: none;
        }
    }
}