$color-borde: #CBD5E0;
$color-error: #dd140d;
$color-focus: rgba(0, 84, 78, 0.3);

.info-cliente {
    width: 100%;
    margin: auto;
    background-color: $color-secundario;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;

    .formulario-datos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;

        label {
            color: black;
            font-weight: 600;
            display: block;
            margin-bottom: 5px;
            position: relative;

            &::after {
                content: "*";
                color: $color-error;
                font-size: 1em;
                margin-left: 4px;
                position: absolute;
            }
        }

        input, select {
            width: 90%;
            padding: 12px;
            font-size: 16px;
            border: 1px solid $color-borde;
            border-radius: 6px;
            background-color: #ffffff;
            color: $color-parrafos;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
            transition: border-color 0.3s ease, box-shadow 0.3s ease;

            &:focus {
                border-color: $color-textos;
                box-shadow: 0 0 6px $color-focus;
                outline: none;
            }

            &::placeholder {
                color: lighten($color-parrafos, 20%);
            }
        }

        select {
            appearance: none;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23' + $color-parrafos + '"><polygon points="0,0 16,0 8,8"/></svg>');
            background-position: right 12px center;
            background-repeat: no-repeat;
            background-size: 12px;
        }
    }

    @media (max-width: 768px) {
        .formulario-datos {
            grid-template-columns: 1fr;
        }
    }
}

@media (max-width: 700px) {
    .info-cliente {
        .formulario-datos {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            input, select {
                width: 50%
            }
        }
    }
    
}
