.aviso-cookies {
    position: fixed;
    margin: 20px;
    bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fondo-aviso-cookies {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(47, 47, 47, 0.3);
    z-index: -1;
}

.galleta {
    width: 50px;
    height: auto;
}

.titulo {
    font-size: 1.5em;
}

.boton {
    background-color: $color-textos;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
}

.boton:hover {
    background-color: #218838;
}

.enlace {
    margin-top: 10px;
    color: #0c437d;
    text-decoration: none;
}
