.pagina-login {
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: $color-base;
    @include flex-column;
    justify-content: center;
    align-items: center;    
    
    .pagina-login-contenedor {
        box-shadow: 10px 10px 5px 0px rgba(0, 84, 78, 1);
        padding: 20px;
        border: 1px solid $color-textos;
        width: 50%;
        height: 50%;
        @include flex-column;
        justify-content: center;
        align-items: center;

        h2 {
            text-align: center;
            color: $color-textos;
        }

        .form-inicio-sesion-admin {
            display: flex;
            width: 100%;
            justify-content: center;
            .pagina-login-form {
                @include flex-column;
                width: 90%;
                justify-content: center;
                align-items: center;
                gap: 20px;
                .pagina-login-inputs {
                    width: 70%;
                    @include flex-column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
        
                    input {
                        width: 100%;   
                        height: 40px;
                        border-radius: 80px 20px;
                        border-color: $color-textos;
                        padding-left: 20px;
    
                        &:hover {
                            box-shadow: 6px 8px 8px -2px rgba(0, 84, 78, 1);
                        }
    
                        &:focus {
                            box-shadow: 6px 8px 8px -2px rgba(0, 84, 78, 1);
                        }

                        &::placeholder {
                            padding-left: 20px;
                        }
                    }
                }
    
                button {
                    width: 70%;
                    height: 30px;
                    border: 1px solid $color-textos;
                    border-radius: 5px;
                    background-color: $color-textos;
                    color: $color-base;
                    cursor: pointer;
    
                    &:hover {
                        border: 1px solid black;
                        background-color: $color-base;
                        color: $color-textos;
                    }
                }
            }
        }
    }
}