.about {
    @include flex-column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .contenedor-imagen {
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        background-color: white;
        width: 70%;
        border-radius: 20px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

        .imagen-redondeada {
            object-fit:contain;

            img {
                width: 250px;
                height: 250px;
                border-radius:   0 50% 0 50%;
                object-fit: cover;
                object-fit: contain;
            }
        }

        .texto-biografia {
            max-width: 400px;
            gap: 20px;
            color: #00544e;

            .bio-h1 {
                h1 {
                    font-size: 24px;
                    text-align: center;
                }
            }

            .bio-p {
                p {
                    font-size: 16px;
                    text-align: justify;
                    line-height: 2;
                }
            }
        }

        .redes-sociales {
            color: $color-textos;
    
            .iconos-redes {
                .contacto {
                    margin: 10px;
                    padding: 10px;
                    border: 1px solid $color-textos;
                    a {
                        text-decoration: none;
                        color: $color-textos;
                        img {
                            width: 60px;
                        }
                        .fa-icon {
                            font-size: 30px;
                        }
                    }

                    &:hover {
                        background-color: $color-base;
                    }
                }
            }
        }
    }
}

.texto-bio-p {
    font-weight: 900;
}


@media (max-width: 1124px) {
    .contenedor-imagen {
        width: 90%;
        
    }
}

@media (max-width: 523px) {
    .contenedor-imagen {
        flex-direction: column;
        width: 100%;
        justify-content: center;

        .texto-biografia{
            .bio-h1 {
                h1 {
                    font-size: 18px;
                }
            }

            .bop-p {
                padding: 10px;
            }
        } 
    }
}