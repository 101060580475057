.pie-pagina {
    margin-top: 40px;
    background-color: $color-secundario;
    @include flex-row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 40px;

    .paginas-interes {
        @include flex-column;
        justify-content: left;
        align-items: center;
        gap: 40px;

        a {
            text-decoration: none;
            color: $color-parrafos;

            &:hover {
                color: $color-textos;
                text-decoration: underline;
            }
        }
    }

    .servicios-contenedor {
        .servicios-iconos {
            @include flex-row;
            justify-content: left;
            align-items: center;
            gap: 40px;
            color: $color-parrafos;
        }
    }

    .datos-contacto {
        @include flex-column;
        align-content: center;
        .contacto {
            @include flex-row;
            justify-content: left;
            align-items: center;
            margin: 20px;
            gap: 20px;
            color: $color-parrafos;


            a {
                text-decoration: none;
                color: $color-parrafos;

                &:hover {
                    color: $color-textos;
                    text-decoration: underline;
                }
            }
        }
    }

}

.url-kaizen {
    color: #111125;
    text-decoration: none;

    &:hover {
        color: #333355;
        text-decoration: underline;
    }
}

@media (max-width: 662px) {
    .pie-pagina {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
}
