.cesta {
    width: 100%;
    @include flex-column;
    justify-content: center;
    text-align: center;
    align-items: center;

    .cesta-y-pago {
        @include flex-column;
        width: 90%;
        gap: 20px;

        .contenido-cesta {
            background-color: $color-secundario;
            padding-bottom: 20px;

            .producto-cesta {
                display: grid;
                grid-template-columns: 3fr 1fr;
                align-items: center;
                text-align: left;
                gap: 10px;

                .nombre-cantidad-precio {
                    @include flex-row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    padding: 10px;
                    box-shadow: 0px 10px 6px -3px rgba(219,181,181,1);
                
                    .nombre-producto-cesta {
                        text-transform: uppercase;
                    }

                    .cantidad-precio-cesta  {
                        @include flex-column;
                        justify-content: center;
                        text-align: right;
                        gap: 10px;
                        .precio-subtotal-cesta {
                            p{
                                font-weight: 900;
                            }
                        }
                    }
                    
                }

                .imagen-en-pago {
                    width: 100px;
                    margin-right: 10px;
                }
            }

            .total-a-pagar {
                color: $color-textos;
            }
        }
    }
}

.botones-pago {
    margin-bottom: 40px;;
}