.productos-link {
    margin-top: 40px;
    @include flex-row;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .seccion {
        @include flex-column;
        justify-content: center;
        align-items: center;

        img {
            width: 200px;
            height: 200px;
            object-fit: fit;
        }

        a {
            text-transform: lowercase;
            text-decoration: none;
            color: #00544e;
        }

        h2 {
            text-transform: uppercase;
        }
    }
}

@media (max-width: 775px) {
    .generos-productos-link {
        @include flex-row;

        .seccion {
            img {
                width: 100px;
                height: 100px;
            }

            h2 {
                font-size: 1.2em;
            }
        }
    }
}