.contenedor-navegador {
    width: 100%;
    font-size: 1.5em;
}
  
.subcontenedor-navegador {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: $color-textos;
}
  
.logo img {
    height: 90px;
    margin: 20px;
}
  
.navegador {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
}
  
.hamburger {
    display: none;
    background: none;
    border: none;
    color: black;
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: auto;
    padding-right: 50px;
}
  
.navegador-principal {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    justify-content: center;
    gap: 20px;
}
  
.navegador-principal li {
    position: relative;
    text-decoration: none;
    color: black;
}

.nav-link-activo , 
.productos {
    text-decoration: none;
    color: black;
}

.outlet {
    text-decoration: none;
    color: rgb(116, 9, 9);
    font-weight: 600;
}
  
.navegador-subcategoria {
    display: none;
    position: absolute;
    background-color: #037b73;
    list-style-type: none;
    padding: 10px;
    margin: 0;
    border: 1px solid #037b73;
    z-index: 10;
    border-radius: 5%;
}

.navegador-subcategoria li:hover {
    background-color: #035c60;
    transition: background-color 0.3s ease;
}

.navegador-subcategoria li a{
    text-decoration: none;
    color: black;
}

.navegador-principal li:hover .navegador-subcategoria {
    display: block;
}

@media (max-width: 850px) {
    .contenedor-navegador {
        font-size: 1.3em;
    }
}
  
@media (max-width: 780px) {
    .contenedor-navegador {
        font-size: 1em;
    }
    .navegador-principal {
        display: none; // Ocultar enlaces en pantallas pequeñas
        flex-direction: column;
        min-width: 200px;
        position: absolute;
        top: 53px; // Ajusta según la altura del header
        left: -5;
        right: 0;
        background-color: #037b73; // Mantener el fondo del header
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); // Sombra suave
        transition: all 0.3s ease; // Transición suave
        z-index: 10;
        border-radius: 5%;
    }

    .navegador.open .navegador-principal {
        display: flex; // Mostrar menú cuando está abierto
    }

    .hamburger {
        display: block; // Mostrar botón hamburguesa
    }

    .navegador-principal li {
        padding: 10px; // Espaciado interno
        text-align: center; // Centrar texto
        color: white; // Color de texto en el menú desplegable
    }

    .navegador-principal li:hover {
        background-color: #035c60; // Color de fondo en hover
        transition: background-color 0.3s ease; // Transición suave
    }
}
