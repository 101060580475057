.contenedor-productos-api {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    gap: 10px;
    margin: 10px;

    .contenedor-stock {
        height: auto;
        display: flex;
        justify-content: center;
        gap: 10px;
        padding-top: 10px;
        color: $color-base;
    
        .item {
            background-color: $color-secundario;
            @include flex-column;
            justify-content: space-evenly;
            align-items: center;
            object-fit: cover;
            overflow: hidden;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            .info-producto {
                display: flex;
                @include flex-column;
                padding: 5px;
                text-align: center;
                justify-content: center;
                h3 {
                    color: $color-textos;
                }
                p {
                    color: $color-textos;
                }
                .precio{
                    font-weight: bold;
                    margin-bottom: 10px;
                    color: $color-textos;
                }

                .botoneess {
                    @include flex-row;
                    gap: 10px;
                    button, .boton-ver-mas {
                        text-decoration: none;
                        background-color: $color-base;
                        border: none;
                        padding: 5px;
                        border-radius: 10px;
                        color: $color-textos;
                        font-size: 1em;
                        font-weight: bold;
                        cursor: pointer;
                        transition: background-color 0.3s ease, box-shadow 0.3s ease;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              
                        &:hover {
                          background-color: darken($color-base, 10%);
                          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 1124px) {
    .contenedor-productos-api {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }  
}

@media (max-width: 722px) {
    .contenedor-productos-api {
        grid-template-columns: 1fr 1fr 1fr;
    }  
}
