.contenedor-home {
    @include flex-column;
    text-align: center;
    overflow: hidden;
    gap: 30px;

    h2 {
        color: $color-textos;
    }

    .imagen-bienvenida-home {
        height: auto;
        @include flex-row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 10px;
        background-color: $color-base;
    
        .texto-bienvenida {
            @include flex-column;
            justify-content: center;
            text-align: center;

            p {
                color: $color-parrafos;
            }
        
            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
            
                to {
                    opacity: 1;
                }
            }
            
            #mi-titulo {
                opacity: 0;
                animation-name: fadeIn;
                animation-duration: 4s;
                animation-fill-mode: forwards;
                color: $color-textos;
            }  
        }   
    }

    .productos-link {
        .seccion {
            img {
                transition: filter 0.3s ease-in-out;
    
                &:hover {
                    filter: blur(2px);
                }
            }
        }
    }


    .mas-info-home {
        border-top: 1px solid $color-parrafos;

        .mas-info {
            @include flex-row;
            width: 100%;
            justify-content: space-around;
            align-items: center;
        
            .beneficio {
                border: solid 1px $color-parrafos;
                padding: 20px;
                @include flex-column;
                justify-content: center;
                align-items: center;
        
                img {
                    border-radius: 50% 0 50% 0;
                    width: 200px;
                    object-fit: cover;
                }
        
                h4 {
                    text-align: center;
                    color: $color-parrafos;
                }
            }  
        }
    }  
}



@media (max-width: 775px) {
    .contenedor-home {
        .imagen-bienvenida-home {
            gap: 10px;
            .texto-bienvenida {
                h3 {
                    font-size: 1em;
                }

                p {
                    font-size: 0.8em;
                }
            }
        }

        .productos-link {
            .seccion {
                .texto-h2 {
                    font-size: 0.7em;
                }
                img {
                    width: 180px;
                    height: 180px;
                }
            }
        }

        .mas-info-home {
            .mas-info {
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                .beneficio {
                    border: 0;
                    border-bottom: 1px solid $color-parrafos;
                    img {
                        width: 30%;
                        padding: 10px;
                    }
                    
                }
            }
        }
        
    } 
}


@media (max-width: 620px) {
    .contenedor-home {
        .productos-link {
            .seccion {
                .texto-h2 {
                    font-size: 0.6em;
                }
                img {
                    width: 110px;
                    height: 110px;
                }
            }
        }
    }
}
