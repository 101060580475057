@mixin button($color) {
    width: 100%;
    background-color: $color;
    color: black;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    gap: 8px;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin boton-general($color) {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: $color;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;

    &:hover {
        background-color: darken($color, 10%);
        transform: translateY(-2px);
    }
}