.app-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;

    .App {
        width: 1400px;
    }
}