.productos-de-muestra-home {
    @include flex-column;
    width: 100%;
    text-align: center;
    align-items: center;
    background-color: $color-base;
    border-top: 1px solid $color-secundario;    
    .titulo-fotos-aleatorios{
        h3 {
            color: $color-textos
        }
    }
    
    .home-productos-de-muestra {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;

        .producto-de-muestra {
            width: 250px;
            margin: 10px;
            @include flex-column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 5px;;
            box-shadow: 6px 5px 5px 0px rgba(0,0,0,0.41);

            .imagen-producto-muestra {
                img {
                    width: 100%;
                    height: 30vh;
                    object-fit: cover;
                    display: block;
                }
            }

            h4 {
                margin-bottom: 0;
            }

            p {
                margin: 0;
            }
            
           .contenedor-carrito-fotos-aleatorios {
                @include flex-row;
                gap: 5px;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                .fotos-aleatorios-precio {
                    .precio {
                        font-weight: bold;
                        margin: 10px;
                        color:  $color-textos;
                    }
                    
                }

                .fotos-aleatorios-carrito {
                    margin: 0px;
                    button {
                        background-color: $color-base;
                        border: none;
                        
                    
                        &:hover {
                            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);

                        }
                    
                    }
                    .icono-bag-shopping {
                        font-size: 20px;
                        color: $color-textos;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                
           }
        }
    }

    .cta-link-productos {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        background-color: $color-textos;
        margin-top: 20px;
        border: 2px solid #D2C3B5;
        padding: 10px 20px;
        font-weight: bold;
        border-radius: 8px;
        transition: all 0.4s ease;
        cursor: pointer;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        
    
        &:hover {
            box-shadow: 0 10px 17px rgba(0, 0, 0, 0.2);
        }          
    
        a {
            text-decoration: none;
            color: $color-base;
        }

        a:hover {
            color: $color-secundario;
        }
    }

}


@media (max-width: 1265px) {
    .productos-de-muestra-home {
        .home-productos-de-muestra {
            .producto-de-muestra {
                width: 150px;
                .imagen-producto-muestra {
                    img {
                    
                        height: 20vh;
                    }
                }

                
                
            } 
        }
    } 
}

@media (max-width: 1000px) {
    .productos-de-muestra-home {
        .home-productos-de-muestra {
            .producto-de-muestra {
                .imagen-producto-muestra {
                    img {
                        height: 15vh;
                    }
                }
                
            } 
        }
    }  
}

@media (max-width: 728px) {
    .home-productos-de-muestra {
        h4 {
            font-size: 0.8em;
        }
    }
}

@media (max-width: 728px) {
    .productos-de-muestra-home {
        .home-productos-de-muestra {
            .producto-de-muestra {
                .contenedor-carrito-fotos-aleatorios {
                    .fotos-aleatorios-carrito{
                        .icono-bag-shopping {
                            font-size: 15px;
                        }
                    }
                        

                }
                
            } 
        }
    }
}
