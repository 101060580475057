.boton-whatsapp {
    position: fixed;
    bottom: 20px; /* Distancia desde la parte inferior */
    right: 20px; /* Distancia desde la derecha */
    width: 60px; /* Ancho del botón */
    height: 60px; /* Altura del botón */
    border-radius: 50%; /* Para hacer el botón redondo */
    background-color: #25D366; /* Color de fondo de WhatsApp */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Sombra del botón */
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.boton-whatsapp img {
    width: 40px; /* Ajusta el tamaño del logo de WhatsApp */
    height: 40px; /* Ajusta el tamaño del logo de WhatsApp */
}
